/* ReportGenerator.css */

.report-generator {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .report-generator h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .channels-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .channel-item {
    width: 50%;
    margin-bottom: 5px;
  }
  
  .report-type-options {
    display: flex;
    gap: 20px;
  }
  
  .user-credits, .available-reports {
    margin-bottom: 20px;
  }
  
  .user-credits h3, .available-reports h3 {
    margin-bottom: 10px;
  }
  
  .available-reports table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .available-reports th, .available-reports td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .available-reports th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .error-message {
    color: red;
    margin-bottom: 15px;
  }
  
  .cost-display {
    margin-bottom: 15px;
    font-size: 1.1em;
  }
  
  .form-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .form-actions button {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .download-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .download-link a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .download-link a:hover {
    text-decoration: underline;
  }
  
  .beta-text {
    text-align: center;
    color: red; /* ensures the BETA text is red */
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .red-text {
    color: red; /* makes the disclaimer text red */
  }
  

  @media (max-width: 600px) {
    .channel-item {
      width: 100%;
    }
  }
  